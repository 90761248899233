import { isEmpty } from 'lodash';

const isServer = typeof window === 'undefined';

declare global {
  interface Window {
    Intercom: any;
  }
}
const isAnAdminPage = (router) => {
  // Home page and the hub page has the word 'live-preview' in the URL. However, it's not an admin page.
  return router.pathname.includes('live-preview');
};

const isAnAdmin = (user) => {
  const { roleName } = user;
  return [
    'platform_admin',
    'site_admin',
    'hub_admin',
    'project_admin',
  ].includes(roleName);
};

export const intercomNode = ({ pageProps, router }) => {
  const props = pageProps?.initialReduxState;
  const hideIntercom = pageProps.hideIntercom || false;
  const {
    librariesDetails: { intercom = {} } = {},
    currentUser: { user = {} } = {},
    currentSite: { site = {} } = {},
    features: { features = {} } = {},
    siteFeatures: { siteFeatures = {} } = {},
    opencitiesIntegration: { opencitiesMeta = {} } = {},
  } = props || {};
  // Don't render Intercom on the participant side and on development and if user is not an admin
  if (isAnAdminPage(router) || !isAnAdmin(user)) return false;

  if (!isServer && !isEmpty(intercom)) {
    return window?.Intercom?.('boot', {
      app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
      ...intercom,
      hide_default_launcher: hideIntercom,
      sms_ideas: features['marketplace_sms_integration']?.enabled || false,
      whitelist_embed: siteFeatures?.features?.includes('whitelist-embed'),
      new_homepage_published_is: site.publishedHomePageRevisions,
      appearance_editor_published_is: site.publishedHomePageRevisions,
      revised_survey: features['show_revised_survey']?.enabled || false,
      opencities_connector_is: opencitiesMeta?.total >= 1 || false,
    });
  }
};
