import 'core-js/features/math/log10';
import 'core-js/features/dom-collections/for-each';
import 'core-js/features/object/assign';
import 'polyfills/to-blob';
import 'spectrum-colorpicker';

import Model from 'api/model';
import isProduction from 'lib/is-production';
import TokenStore from 'lib/token-store';

// Setup http adapter for api client
Model.configureAdapter({
  host:
    isProduction || !process.env.NEXT_PUBLIC_DEV_API_ENDPOINT
      ? `${window.location.protocol}//${window.location.host}`
      : process.env.NEXT_PUBLIC_DEV_API_ENDPOINT,
  namespace: '/api/v2',
  headers: {
    'x-api-token': `Bearer ${TokenStore.apiToken}`,
  },
});
