const regionMapping = {
  'Asia Pacific (Sydney) Region': 'AU',
  AUSTRALIA: 'AU',
  new_zealand: 'AU',
  CANADA: 'CA',
  UNITED_KINGDOM: 'UK',
  united_states: 'US',
};

export const getGroupId = (region: string, siteId: string): string => {
  return getRegionCode(region) + '-' + siteId;
};

const getRegionCode = (region: string): string => {
  let regionCode = '';
  Object.keys(regionMapping).forEach((regionKey) => {
    if (region.toLowerCase() === regionKey.toLowerCase()) {
      regionCode = regionMapping[regionKey];
    }
  });
  if (regionCode === '') {
    region = region.replace(/ /g, '_');
    const regionArray: string[] = region.split('_');
    if (regionArray.length > 2) {
      regionCode = regionArray[0].charAt(0) + regionArray[1].charAt(0);
    } else if (regionArray.length === 1 && regionArray[0].length > 2) {
      regionCode = regionArray[0].charAt(0) + regionArray[0].charAt(1);
    }
  }

  return regionCode.toUpperCase();
};
