module.exports = (function () {
  'use strict'
  if (typeof HTMLCanvasElement === 'undefined' || HTMLCanvasElement.prototype.toBlob) {
    return
  }
  Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
    value: function (callback, type, quality) {
      var canvas = this
      setTimeout(function () {
        var binStr = atob(canvas.toDataURL(type, quality).split(',')[1])

        var len = binStr.length

        var arr = new Uint8Array(len)

        for (var i = 0; i < len; i++) {
          arr[i] = binStr.charCodeAt(i)
        }

        callback(new Blob([arr], { type: type || 'image/png' }))
      })
    }
  })
})()
