import Router from 'next/router';

import { getGroupId } from '../lib/segment-helper';
import { Site } from '../types/site';
import { User } from '../types/user';
const isEmpty = (obj): boolean => {
  return !obj || Object.keys(obj).length === 0;
};
let isSegmentEnabled;

// Function to import and add segment in the application with global scope.
export const initializeSegment = (): string => {
  const key: string = process.env.NEXT_PUBLIC_SEGMENT_KEY;
  // Reference: https://segment.com/docs/getting-started/02-simple-install/
  return ` var analytics = window.analytics = window.analytics || [];
    if (!analytics.initialize)if (analytics.invoked)window.console && console.error && console.error("Segment snippet included twice.");
    else { analytics.invoked = !0;
    analytics.methods = ["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];
    analytics.factory = function (t) {return function () {var e = Array.prototype.slice.call(arguments); e.unshift(t); analytics.push(e); return analytics }}; 
    for (var t = 0; t < analytics.methods.length; t++) { var e = analytics.methods[t]; analytics[e] = analytics.factory(e) }
    analytics.load = function (t) {var e = document.createElement("script");e.type = "text/javascript"; e.async = !0;
    e.src = ("https:" === document.location.protocol ? "https://" : "http://") + "cdn.segment.com/analytics.js/v1/" + t + "/analytics.min.js";
    var n = document.getElementsByTagName("script")[0]; n.parentNode.insertBefore(e,n)}; analytics.SNIPPET_VERSION = "4.0.0"; analytics.load("${key}");} `;
};

/**
 * Function: Adds user tracking to the application.
 * {Object} pageProps -  Contains the data from redux store
 * {email} email - Current logged in user email.
 * {Date} lastSeenAt - last seen date of current user.
 * {String} login - Current user's username.
 * {String} role - Current user role. Can be one of (platfom_admin, site_admin, project_admin, preview_user, blocked_user, hub_admin)
 * {String} userType - Specifies class of user.
 */

export const trackCurrentUser = (
  currentUser: User,
  currentSite: Site,
  isSegmentEnabled: boolean,
): void => {
  if (
    isSegmentEnabled &&
    !isEmpty(currentUser) &&
    !isEmpty(currentSite) &&
    window
  ) {
    window.analytics.identify(currentUser.id, {
      userEmail: currentUser.email,
      lastSeenAt: new Date(currentUser.lastSeenAt).toUTCString(),
      userLogin: currentUser.login,
      userRole: currentUser.roleName,
      userType: currentUser.type,
      siteId: currentSite.id,
      siteName: currentSite.name,
      siteRegion: currentSite.region,
      siteLocale: currentSite.locale,
    });
  }
};

/**
 * Function: Adds page tracking to the application.
 * {Object} pageProps -  Contains the data from redux store.
 * {Number} siteId - Contains a id corosponding to a site
 * {String} siteName - Name of the current site
 * {sting} siteRegion - Region the site has been opened.
 * {email} userEmail - currently logged in user's email.
 * {String} userLogin - Current user's username.
 * {String} role - Current user role. Can be one of (platfom_admin, site_admin, project_admin, preview_user, blocked_user, hub_admin).
 * {String} userType - Specifies class of user.
 */
export const trackCurrentPage = (
  currentUser: User,
  currentSite: Site,
  isSegmentEnabled: boolean,
) => {
  if (
    isSegmentEnabled &&
    !isEmpty(currentSite) &&
    !isEmpty(currentUser) &&
    window
  ) {
    window.analytics.page({
      siteId: currentSite.id,
      siteName: currentSite.name,
      siteRegion: currentSite.region,
      userEmail: currentUser.email,
      userLogin: currentUser.login,
      userRole: currentUser.roleName,
      userType: currentUser.type,
    });
  }
};

/**
 * Function: Adds user tracking on login and page tracking on url change.
 * {Object} pageProps -  Contains the data from redux store.
 */
export const initializeSegmentTrackers = (
  user,
  site,
  isAdminSegmentEnabled,
  isParticipantSegmentEnabled,
) => {
  isSegmentEnabled = isTrackable(
    isAdminSegmentEnabled,
    isParticipantSegmentEnabled,
  );
  if (isSegmentEnabled) {
    trackCurrentUser(user, site, isSegmentEnabled);
    trackGroup(site, isSegmentEnabled);
    trackCurrentPage(user, site, isSegmentEnabled);
  }

  Router.events.on('routeChangeComplete', (url) => {
    if (isSegmentEnabled) trackCurrentPage(user, site, isSegmentEnabled);
  });
};

/**
 * Function: Checks the page url and checks admin toggle for admin tracking pages and participant toggle for participant BH pages
 * {Boolean} isAdminSegmentEnabled -  Toggle boolean flag for admin tracking.
 * {Boolean} isParticipantSegmentEnabled -  Toggle boolean flag for Participant tracking.
 */
const isTrackable = (isAdminSegmentEnabled, isParticipantSegmentEnabled) => {
  const participantUrlPartialMatch = ['live-preview'];
  const isParticipantPage = checkIfUrlContainsSegment(
    participantUrlPartialMatch,
  );
  if (isParticipantPage && isParticipantSegmentEnabled) return true;
  else if (!isParticipantPage && isAdminSegmentEnabled) return true;

  return false;
};

/**
 * Function: Checks if any of the strings in the array are in the URL.
 * {Array} urlSegments -  Contains array of string to check whether they exist in the URL.
 */
const checkIfUrlContainsSegment = (urlSegments) => {
  let hasUrlSegment = false;
  urlSegments.forEach((urlSegment) => {
    if (
      Router.asPath.includes(urlSegment) ||
      Router.router.route.includes(urlSegment)
    ) {
      hasUrlSegment = true;
    }
  });
  return hasUrlSegment;
};

/**
 * Function: Adds group tracking to the application.
 * {Object} currentSite -  Contains the site data from the redux store
 * {Boolean} isSegmentEnabled - Boolean feature toggle
 */
export const trackGroup = (currentSite: Site, isSegmentEnabled: boolean) => {
  if (isSegmentEnabled && !isEmpty(currentSite) && window) {
    window.analytics.group(getGroupId(currentSite.region, currentSite.id), {
      group_type: 'Site',
      siteId: currentSite.id,
      siteName: currentSite.name,
      siteRegion: currentSite.region,
      siteLocale: currentSite.locale,
    });
  }
};

/**
 * Function: Adds group tracking to the application.
 * {String} trackingItemName - The name of the tracking object
 * {Object} trackingData -  All data specific to the tracking object
 */
export const track = (trackingItemName, trackingData = {}) => {
  if (isSegmentEnabled && window) {
    window.analytics.track(trackingItemName, {
      ...trackingData,
    });
  }
};
